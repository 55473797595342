import React from 'react'

import PageWithText from '../components/PageWithText'
import P from '../components/P'
import SEO from '../components/seo'

export default () => {
  return (
    <PageWithText title="SERVICII">
      <SEO title="Servicii de educație biblică" />
      <P>
        Daca sunteți pastor sau lucrător, aveți o chemare înaltă, cu multe
        cerințe. Ca rezultat, sunteți o persoană foarte ocupată. Cum putem să vă
        ajutăm?
      </P>

      <P>
        <P>
          Avem o echipă de oameni pregătiți – misionari străini și români, care
          este la dispoziția acestei lucrări. Ei vă vor sprijini în diferite
          moduri, având în vedere scopul lucrării EBE și misiunea bisericii
          locale:
        </P>
        <ul>
          <li>
            Putem să vă ajutăm să concepeți o strategie pentru un centru de
            pregătire cu baza în biserica locală în vederea echipării de
            slujitori;
          </li>
          <li>
            Putem furniza cursuri folositoare programului sau centrului dvs. de
            echipare;
          </li>
          <li>
            Putem să vă ajutăm să concepeți o strategie a unei lucrări pentru
            femei în biserica locală;
          </li>
          <li>
            Putem face vizite pentru a vă asista și încuraja în lucrarea de
            echipare;
          </li>
          <li>
            Vă putem oferi seminarii, consultanță și instruire potrivit
            filozofiei educației creștine și echipării lucrătorilor;
          </li>
          <li>
            Vă putem oferi un program de studiu intensiv de Teologie Pastorală
            pentru pastori și slujitori.
          </li>
        </ul>
      </P>

      <P>
        <P mt={5}>Câteva avantaje ale pregătirii prin lucrarea EBE:</P>
        <ul>
          <li>
            Posibilitatea de adaptabilitate a cursurilor în funcție de nivelul
            de pregătire al participanților.
          </li>
          <li>
            Diversitatea de domenii, acoperită prin programa de pregătire, prin
            cursurile oferite.
          </li>
          <li>
            Cost financiar redus – se acopera doar costurile de editare ale
            cursurilor.
          </li>
          <li>
            Echiparea se face in biserica locala, fara a implica nevoia
            deplasării din contextul în care se găsesc cursanții.
          </li>
          <li>
            Pregătirea oferă în egală măsura o creștere în cunoștințe,
            îndemânare și caracter.
          </li>
          <li>
            Programa urmărește pregătirea de “echipatori”, care se vor
            multiplica în urmatoarele generații de slujitori (conf. 2 Tim. 2:2).
          </li>
          <li>
            Cursanții sunt echipați “în slujire”, în contrast cu o echipare
            “pentru slujire”.
          </li>
        </ul>
      </P>
    </PageWithText>
  )
}
