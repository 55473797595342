import React from 'react'
import { Text, Box } from 'rebass'
import Layout from './layout'

import line from '../line'

export default ({ title, children }) => {
  return (
    <Layout>
      <Text fontSize={4} mb={3}>
        {title}
      </Text>
      {line}

      <Box color="textgray">{children}</Box>
    </Layout>
  )
}
